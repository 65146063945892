/* eslint-disable react/no-unknown-property */
import { Viewer3D, DeepUpModel, BoundsOnLoad } from '@deepup/viewer3d';
import styled from '@emotion/styled';
import { type Theme } from '@mui/material';
import { Html } from '@react-three/drei';
import { type ReactNode, Suspense } from 'react';

import { ErrorPage } from '@components/ErrorPage';
import { FetchError, useApiHdMesh } from '@hooks/useApiHdMesh';
import { useThemeMode } from '@hooks/useThemeMode';

import { LoadingScreen } from './LoadingScreen';

export type MeshViewerProps = {
  className?: string;
  scanId: string;
  measurementView: ReactNode;
  onCanvasClick: () => void;
};

const MeshViewerStyled = styled.div<{ theme?: Theme }>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const MeshViewer = ({
  className,
  scanId,
  measurementView,
  onCanvasClick,
}: MeshViewerProps) => {
  const { data: manifest, error, isLoading } = useApiHdMesh(scanId);
  const { isDarkMode } = useThemeMode();

  if (isLoading) {
    return <LoadingScreen isDarkMode={isDarkMode} />;
  }

  if (error) {
    if (!(error instanceof FetchError)) {
      console.error(error);
    }

    return (
      <ErrorPage
        errorText={
          error instanceof FetchError
            ? error.error.message
              ? error.error.message
              : (error.error.error ?? 'Unknown error')
            : 'Unknown error'
        }
        statusCode={error instanceof FetchError ? error.res.status : undefined}
      />
    );
  }

  if (!manifest) {
    return <ErrorPage errorText="Manifest could not be loaded" />;
  }

  return (
    <MeshViewerStyled className={className}>
      <Viewer3D
        canvasProps={{
          camera: { position: [0, 3, 3], rotation: [0, 0, 0], fov: 35 },
          onClick: onCanvasClick,
        }}
      >
        <color args={[isDarkMode ? '#121212' : '#dddddd']} attach="background" />
        <Suspense
          fallback={
            <Html fullscreen>
              <LoadingScreen isDarkMode={isDarkMode} />
            </Html>
          }
        >
          <>
            <BoundsOnLoad margin={1.0}>
              <DeepUpModel manifest={manifest} />
            </BoundsOnLoad>
            {measurementView}
          </>
        </Suspense>
      </Viewer3D>
    </MeshViewerStyled>
  );
};
