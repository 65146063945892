import { XOutline } from '@deepup/icons';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useScanImages } from '@hooks/useScanImages';

import { ImageCarousel } from './ImageCarousel';
import { ImageZoom } from './ImageZoom';

export const ImageViewer = ({
  selectedImageId,
  setSelectedImageId,
}: {
  selectedImageId: string;
  setSelectedImageId: (selectedImageId: string | null) => void;
}) => {
  const { scanId } = useParams();
  const { data } = useScanImages({ scanId });
  const images = data?.images ?? [];

  const selectedImage = images.find((img) => img.id === selectedImageId);

  return (
    <Box
      component="div"
      sx={{ borderRight: 1, borderColor: 'divider', width: '100%', height: '100%' }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          height: 48,
          paddingX: 2,
        }}
      >
        <Typography>{selectedImageId}</Typography>
        <Button endIcon={<XOutline />} onClick={() => setSelectedImageId(null)}>
          Detailansicht schließen
        </Button>
      </Box>
      <Box
        alignItems="center"
        component="div"
        display="flex"
        height="85%"
        justifyContent="center"
        paddingTop={2}
      >
        {!selectedImage && <Typography>Image not found</Typography>}
        {selectedImage && (
          <ImageCarousel
            index={images.indexOf(selectedImage)}
            label={'Raw Images'}
            onSlideChange={(index) => {
              const slideImage = images.at(index);

              if (slideImage) {
                setSelectedImageId(slideImage.id);
              }
            }}
            slides={images.map((img) => (
              <ImageZoom
                image={
                  <img
                    alt=""
                    key={img.id}
                    src={img.downloadUrl}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                }
                key={img.id}
              />
            ))}
            sx={{ height: '100%', width: '100%' }}
          />
        )}
      </Box>
    </Box>
  );
};
